import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function Rewards({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('Rewards');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>Rewards</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default Rewards;

const DATA = [
    {
        title: 'How many ways can we generate rewards?',
        content: 'There are 4 ways of reward generation. Namely - BUSD Rewards, Classic Staking, Bounty Rewards, Autocompound'
    },
    {
        title: 'The Auto-Compound is triggered for everyone, once the bounty reward is claimed. It has 2% fees on vault entry and exit. Also a 2% relock bonus for 30days'
    },
    {
        title: 'What are the fees for staking?',
        content: 'There is a 2% entry and exit fee for staking and unstaking. For classic staking is flexible. You can stake and unstake whenever!'
    },
    {
        title: 'Why can\'t I claim BUSD reward?',
        content: 'To be eligible for the HPAY BUSD reward mechanics, you must stake at least 800k HPAY. These rewards can be claimed at any time on a prorated fee basis'
    },
    {
        title: 'What is BUSD Reward?',
        content: 'Requires 800,000 HPAY to unlock your BUSD Reward. Has early claiming fees 30%'
    },
    {
        title: 'Why HedgePay staking are better?',
        content: 'Other exchanges are stable but has much less attractive APR and has no price increase potential. However, in HedgePay there’s a higher APR with a potential price increase'
    },
    {
        title: 'What is Bounty Reward?',
        content: 'Bounty is used for moving the BUSD profits to the holders and bounty rewards can only be claimed once a day and on first comes first serves basis'
    },
    {
        title: 'Where can i find the reward calculator or table?',
        content: 'The HedgePay Team has not complete the reward calculator yet. Expected to complete before 2023 Quater 1'
    }
]